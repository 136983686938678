<template>
    <div class="content-wrapper">
        <!-- Content Header (Page header) -->
        <div class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1 class="m-0 text-dark">All Costs</h1>
                    </div><!-- /.col -->
                    <div class="col-sm-6">
                        <button class="btn btn-info float-right" @click="showCreateModal"><i class="fas fa-plus-circle"></i> Create</button>
                    </div><!-- /.col -->
                </div><!-- /.row -->
            </div><!-- /.container-fluid -->
        </div>
        <!-- /.content-header -->
        <!-- Main content -->
        <section class="content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="card">
                        <div class="card-header">
                            <form>
                                <div class="row">
                                    <div class="col-xl-2 col-lg-2 col-md-2 col-sm-6 col-xs-12" v-if="authUser.role_id != 7">
                                        <div class="form-group">
                                            <label for="title">{{ $t('globalTrans.hub') }}</label>
                                            <v-select name="hub_id"
                                                v-model="search.hub_id"
                                                label="text"
                                                :reduce="item => item.id"
                                                :options= hubList
                                                :placeholder="$t('globalTrans.select')"
                                            />
                                        </div>
                                    </div>
                                    <div class="col-xl-2 col-lg-2 col-md-2 col-sm-6 col-xs-12">
                                        <div class="form-group">
                                            <label for="start_date">Start Date</label>
                                            <input type="date" id="start_date" v-model="search.start_date" class="form-control" />
                                        </div>
                                    </div>
                                    <div class="col-xl-2 col-lg-2 col-md-2 col-sm-6 col-xs-12">
                                        <div class="form-group">
                                            <label for="end_date">End Date</label>
                                            <input type="date" id="end_date" v-model="search.end_date" class="form-control" />
                                        </div>
                                    </div>
                                    <div class="col-xl-2 col-lg-2 col-md-2 col-sm-6 col-xs-12">
                                        <div class="form-group" style="margin-top:28px;">
                                            <button class="btn btn-info btn-sm mr-2" type="submit" @click.prevent="searchData"><i class="fa fa-search"></i></button>
                                            <a class="btn btn-warning btn-sm" target="_blank" :href="$baseUrl + 'excel-download/hub-cost?start_date='+search.start_date+'&end_date='+search.end_date+'&hub_id='+search.hub_id">
                                                <i class="fa fa-download"></i> Excel
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div class="card-body">
                            <b-overlay :show="loader">
                                <div class="table-responsive">
                                    <table class="table table-bordered table-striped table-sm">
                                        <thead>
                                            <tr class="text-center">
                                                <th>Total Cost Item</th>
                                                <th>Total Cost Amount</th>
                                            </tr>
                                        </thead>
                                        <tbody v-if="summary">
                                            <tr class="text-center text-bold">
                                                <td>{{ summary.total_cost_item }}</td>
                                                <td>{{ summary.total_cost }}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </b-overlay>
                        </div>
                        <div class="card-body">
                            <b-overlay :show="loader">
                                <div class="table-responsive">
                                    <table class="table table-bordered table-striped table-sm">
                                        <thead>
                                            <tr>
                                                <th>ID</th>
                                                <th>Hub</th>
                                                <th>Date</th>
                                                <th>Amount</th>
                                                <th>Reason</th>
                                                <th>Status</th>
                                                <th>Created By</th>
                                                <th style="text-align:center;">Action</th>
                                            </tr>
                                        </thead>
                                        <tfoot>
                                            <tr>
                                                <th>ID</th>
                                                <th>Hub</th>
                                                <th>Date</th>
                                                <th>Amount</th>
                                                <th>Reason</th>
                                                <th>Status</th>
                                                <th>Created By</th>
                                                <th style="text-align:center;">Action</th>
                                            </tr>
                                        </tfoot>
                                        <tbody>
                                            <tr v-for="(item, index) in listData" :key="index">
                                                <td>{{ item.id }}</td>
                                                <td>{{ item.hub.name }}</td>
                                                <td>{{ item.date | dateformat }}</td>
                                                <td>{{ item.amount }}</td>
                                                <td>{{ item.reason }}</td>
                                                <td :class="'cost-status-'+ item.status">{{ getStatus(item.status) }}</td>
                                                <td>{{ item.createdby.name }}</td>
                                                <td style="text-align:center;">
                                                    <slot v-if="item.status === 1">
                                                        <button class="btn btn-warning btn-sm mr-1"  @click="editData(item)"><i class="fas fa-pencil-alt"></i></button>
                                                        <button class="btn btn-danger btn-sm" @click="deleteData(key, item.id)"><i class="fas fa-trash"></i></button>
                                                    </slot>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>           
                                </div>             
                                <div class="mt-3">
                                    <b-pagination
                                        v-model="pagination.currentPage"
                                        :per-page="pagination.perPage"
                                        :total-rows="pagination.totalRows"
                                        @input="searchData"
                                    />
                                </div>
                            </b-overlay>
                        </div>
                    </div>
                </div>
            </div>
        </div><!-- /.container-fluid -->
        </section>
        <div v-if="createModal" class="modal fade show" style="display:block" tabindex="-1" id="addHubModal" Hub="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
            <div class="modal-dialog" Hub="document">
                <div class="modal-content">
                    <b-overlay :show="loader">
                        <div class="modal-header bg-info text-white">
                            <h5 class="modal-title text-center w-100">Create New Cost</h5>
                            <button type="button" class="close" aria-label="Close" @click="cancelModal">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <ValidationObserver ref="createForm" v-slot="{ handleSubmit }">
                                <form @submit.prevent="handleSubmit(saveForm)">
                                    <div class="row">
                                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12" v-if="authUser.role_id != 7">
                                            <ValidationProvider name="Hub" vid="hub_id" v-slot="{errors}" rules="required|min_value:1">
                                                <div class="form-group">
                                                    <label for="hub_id">Hub <span class="text-danger" title="Required">*</span></label>
                                                    <v-select name="hub_id"
                                                        v-model="form.hub_id"
                                                        label="text"
                                                        :reduce="item => item.id"
                                                        :options= hubList
                                                        :placeholder="$t('globalTrans.select')"
                                                    />
                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                </div>
                                            </ValidationProvider>
                                        </div>
                                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                            <ValidationProvider name="Amount" vid="amount" v-slot="{errors}" rules="required">
                                                <div class="form-group">
                                                    <label for="mobile">Amount <span class="text-danger" title="Required">*</span></label>
                                                    <input type="text" id="mobile" v-model="form.amount" class="form-control" placeholder="Amount" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');" />
                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                </div>
                                            </ValidationProvider>
                                        </div>
                                    </div> 
                                    <div class="form-group">
                                        <ValidationProvider name="Reason" vid="reason" v-slot="{errors}" rules="required">
                                            <label for="reason">Reason <span class="text-danger" title="Required">*</span></label>
                                            <input type="text" id="reason" v-model="form.reason" class="form-control" placeholder="Reason" />
                                            <span class="text-danger">{{ errors[0] }}</span>
                                        </ValidationProvider>
                                    </div>
                                </form>
                            </ValidationObserver>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-info" @click.prevent="saveForm">Save</button>
                            <button type="button" class="btn btn-danger" @click="cancelModal">Cancel</button>
                        </div>
                    </b-overlay>
                </div>
            </div>
        </div>
        <div v-if="editModal" class="modal fade show" style="display:block" tabindex="-1" id="editDataModal" Hub="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
            <div class="modal-dialog" Hub="document">
                <div class="modal-content">
                    <b-overlay :show="loader">
                        <div class="modal-header bg-info text-white">
                            <h5 class="modal-title text-center w-100">Edit Cost</h5>
                            <button type="button" class="close" aria-label="Close" @click="cancelModal">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">                            
                            <ValidationObserver ref="editForm" v-slot="{ handleSubmit }">
                                <form @submit.prevent="handleSubmit(updateForm)">
                                    <div class="row">
                                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12" v-if="authUser.role_id != 7">
                                            <ValidationProvider name="Hub" vid="hub_id" v-slot="{errors}" rules="required|min_value:1">
                                                <div class="form-group">
                                                    <label for="hub_id">Hub <span class="text-danger" title="Required">*</span></label>
                                                    <v-select name="hub_id"
                                                        v-model="form_edit.hub_id"
                                                        label="text"
                                                        :reduce="item => item.id"
                                                        :options= hubList
                                                        :placeholder="$t('globalTrans.select')"
                                                    />
                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                </div>
                                            </ValidationProvider>
                                        </div>
                                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                            <ValidationProvider name="Amount" vid="amount" v-slot="{errors}" rules="required">
                                                <div class="form-group">
                                                    <label for="mobile">Amount <span class="text-danger" title="Required">*</span></label>
                                                    <input type="text" id="mobile" v-model="form_edit.amount" class="form-control" placeholder="Amount" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');" />
                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                </div>
                                            </ValidationProvider>
                                        </div>
                                    </div> 
                                    <div class="form-group">
                                        <ValidationProvider name="Reason" vid="reason" v-slot="{errors}" rules="required">
                                            <label for="reason">Reason <span class="text-danger" title="Required">*</span></label>
                                            <input type="text" id="reason" v-model="form_edit.reason" class="form-control" placeholder="Reason" />
                                            <span class="text-danger">{{ errors[0] }}</span>
                                        </ValidationProvider>
                                    </div>
                                </form>
                            </ValidationObserver>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-info" @click.prevent="updateForm">Update</button>
                            <button type="button" class="btn btn-danger" @click="cancelModal">Cancel</button>
                        </div>
                    </b-overlay>
                </div>
            </div>
        </div>
        <div v-if="deleteModal" class="modal fade show" style="display:block" tabindex="-1" id="deleteDataModal" Hub="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-default" Hub="document">
                <div class="modal-content">
                    <b-overlay :show="loader">
                        <div class="modal-header bg-info text-white">
                            <h5 class="modal-title text-center w-100">Delete Confirmation</h5>
                        </div>
                        <div class="modal-body">
                            <input type="hidden" name="del_id" />
                            <h3 class="text-center">Are you sure to delete ?</h3>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-info" @click="destroyData">Delete</button>
                            <button type="button" class="btn btn-danger" @click="cancelModal">Cancel</button>
                        </div>
                    </b-overlay>
                </div>
            </div>
        </div>
        <!-- /.content -->
    </div>
</template>
<script>
import config from '@/config'
import moment from 'moment'
export default {
    name:'Cost',
    data(){
        return {
            loader: false,
            form:{
                hub_id: '',
                date  : moment().format('YYYY-MM-DD'),
                amount: '',
                reason: ''
            },
            summary: null,
            search: {
                hub_id: '',
                start_date: moment().subtract(30,'d').format('YYYY-MM-DD'),
                end_date: moment().format('YYYY-MM-DD')
            },
            form_edit:'',
            itemId: 0,
            errors: [],
            createModal: false,
            editModal: false,
            deleteModal: false,
            key: '',
            pagination: {
                currentPage: 1,
                totalRows: 0,
                perPage: this.$store.state.commonObj.perPage,
                slOffset: 1
            }
        }
    },
    created(){
        this.loadSummary()
        this.loadData()
    },
    watch: {
        stateReload : function (newVal, oldVal) {
            if (newVal != oldVal) {
                this.loadSummary()
                this.loadData()
            }
        }
    },
    computed : {
        stateReload () {
            return this.$store.state.stateReload
        },
        listData () {
            return this.$store.state.list
        },
        hubList () {
            return this.$store.state.commonObj.hubList
        },
        authUser () {
            return this.$store.state.authUser
        }
    },
    methods:{
        searchData () {
            this.loadSummary()
            this.loadData()
        },
        async loadSummary () {
            this.loader = true
            const params = this.authUser.role_id == 7 ? Object.assign({}, this.search, { hub_id: this.authUser.hub_id }) : this.search 
            const response = await config.getData('/hub/cost/summary', params)
            this.loader = false
            this.summary = response.data          
        },
        async loadData(){     
            this.loader = true 
            const params = this.authUser.role_id == 7 ? Object.assign({}, this.search, { hub_id: this.authUser.hub_id, page: this.pagination.currentPage, per_page: this.pagination.perPage }) : Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
            const response = await config.getData('/hub/cost', params)
            this.loader = false
            if (response.success){
                this.$store.dispatch('setList', response.data.data)
                this.paginationData(response.data)
            } else {
                this.$store.dispatch('setList', [])
            } 
        },
        paginationData (data) {
            this.pagination.currentPage = data.current_page
            this.pagination.totalRows = data.total
            this.pagination.perPage = data.per_page
            this.pagination.slOffset = this.pagination.perPage * this.pagination.currentPage - this.pagination.perPage + 1
        },
        showCreateModal(){
            this.createModal = true;
        },
        async saveForm() {
            this.loader = true
            this.$store.dispatch('stateReload', true)    
            if (this.authUser.role_id !== 1) {
                this.form = Object.assign(this.form, { hub_id: this.authUser.hub_id })
            }
            const response = await config.postData('/hub/cost/store', this.form)
            this.loader = false
            if (response.success) {
                this.form.hub_id = this.form.amount = this.form.reason = ''
                this.createModal = false;
                this.$refs.createForm.reset();       
                this.$toast.success({
                    title: 'Success',
                    message: 'Data created successfully',
                    color: '#218838'
                }) 
            } else {
                this.$refs.createForm.setErrors(response.errors)
                this.$toast.error({
                    title: 'Error',
                    message: 'Sorry, something went wrong',
                    color: '#dc3545'
                })   
            }
            this.$store.dispatch('stateReload', false)
        },
        cancelModal(){
            this.createModal = false;
            this.editModal = false;
            this.deleteModal = false;
        },
        editData (hub) {
            this.editModal = true;
            this.form_edit = hub;
        },
        async updateForm(){
            this.loader = true
            this.$store.dispatch('stateReload', true)
            if (this.authUser.role_id !== 1) {
                this.form_edit = Object.assign(this.form_edit, { hub_id: this.authUser.hub_id })
            }
            const response = await config.postData('/hub/cost/update', this.form_edit)            
            this.loader = false
            if (response.success) {
                this.editModal = false         
                this.$toast.success({
                    title: 'Success',
                    message: 'Data updated successfully',
                    color: '#218838'
                }) 
            } else {
                this.$toast.error({
                    title: 'Error',
                    message: response.message,
                    color: '#dc3545'
                })   
            }
            this.$store.dispatch('stateReload', false)
        },
        deleteData(key, itemId){
            this.itemId = itemId;
            this.key = key;
            this.deleteModal = true;
        },
        async destroyData(){
            this.loader = true
            this.$store.dispatch('stateReload', true)
            await config.deleteData('/hub/cost/trash/'+ this.itemId)
            this.loader = false         
            this.deleteModal = false
            this.$toast.success({
                title: 'Success',
                message: 'Data deleted successfully',
                color: '#218838'
            }) 
            this.$store.dispatch('stateReload', false)
        },
        getStatus (status) {
            if (status === 1) {
                return 'Pending'
            } else if (status === 2) {
                return 'Added in Deposit'
            } else if (status === 3) {
                return 'Adjusted'
            }  else if (status === 4) {
                return 'Deleted'
            }
        }
    }
}
</script>